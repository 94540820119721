import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Partners = ({ slice }) => {

  const {title_pt_1, title_pt_2} = slice.primary

  const partImgs = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-auto">
        <img className="" height="120" width="auto" src={item.img.url}/>
      </div>
    )
  })

  return (
    <div className="container section-p-y">
      {(title_pt_1 || title_pt_2) && <h3 className="section-title mb-0">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>}
      <div className="row justify-content-center gy-3">
        {partImgs}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPartners on PrismicHomepageDataBodyPartners {
    primary {
      title_pt_1
      title_pt_2
    }
    items {
      img {
        url
      }
    }
  }
  fragment PageDataBodyPartners on PrismicPageDataBodyPartners {
    primary {
      title_pt_1
      title_pt_2
    }
    items {
      img {
        url
      }
    }
  }
`
