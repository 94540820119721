import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Portfolio = ({ slice, context }) => {

  const {anchor, title_pt_1, title_pt_2} = slice.primary

  const portItems = context.portItems

  return (
    <div id={anchor}>
      <div className="container">
        {(title_pt_1 || title_pt_2) && <h3 className="text-center mb-5">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>}
      </div>
      <div className="container-fluid px-0">
        <div className="row w-100 gy-3 gx-0 gx-sm-3 mx-0">
          {portItems.map((item) => (
            <PortItems item={item} key={item.uid} />
          ))}
          {/*<a class="btn-full-w mt-3" href="/portfolio/">VIEW MORE PROJECTS</a>*/}
        </div>
      </div>
  </div>
  )
}

const PortItems  = ({ item }) => {
  const portfolioLink = `/portfolio/${item.uid}`
  return (
    <div className="col-12 col-sm-6 col-lg-4">
      <a className="port-item bg-light" style={{aspectRatio:"1.6"}} href={portfolioLink}>
        <div className="port-img-overlay" style={{aspectRatio:"1.6"}}>
          <img className="port-main-img" src={item.data.cover_img.url} alt="img"/>
        </div>
        <div className="port-overlay-cont">
          <a className="text-decoration-none" href={portfolioLink}><h5 className="h5 fw-bold text-white text-uppercase">{item.data.title}</h5></a>
          <a className="text-white text-decoration-none" href={portfolioLink}>
            <PrismicRichText field={item.data.desc_short.richText} />
          </a>
        </div>
      </a>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPortfolio on PrismicHomepageDataBodyPortfolio {
    primary {
      anchor
      title_pt_1
      title_pt_2
    }
  }
`
