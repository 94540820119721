import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Features = ({ slice }) => {

  const {anchor, title_pt_1, title_pt_2, bg_img} = slice.primary

  const features = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-md-6 col-lg-4" data-aos="fade-zoom-in" data-aos-delay={index*100}>
				<h4 className="text-white fw-bold" /*style={{borderLeft:"#fff 2px solid", paddingLeft:"12px"}}*/>{item.title}</h4>
        <div className="my-3" style={{height:"2px",width:"32px",background:"#fff"}}/>
        <div className="mb-0-rt">
          <PrismicRichText field={item.text.richText} />
        </div>
      </div>
    )
  })

  return (
    <div id={anchor} className="bg-dark text-white" style={{background:"url(" + bg_img.url + ") 50% 50% no-repeat",backgroundSize:"cover"}}>
      <div className="container section-p-y">
        <h3 className="text-white text-center mb-5">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>
        <div className="row g-5">
          {features}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeatures on PrismicHomepageDataBodyFeatures {
    primary {
      anchor
      title_pt_1
      title_pt_2
      bg_img {
        url
      }
    }
    items {
      title
      text {
        richText
      }
    }
  }
  fragment PageDataBodyFeatures on PrismicPageDataBodyFeatures {
    primary {
      anchor
      title_pt_1
      title_pt_2
      bg_img {
        url
      }
    }
    items {
      title
      text {
        richText
      }
    }
  }
`
