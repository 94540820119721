import { Header } from './Header'
import { Development } from './Development'
import { Marketing } from './Marketing'
import { Partners } from './Partners'
import { Portfolio } from './Portfolio'
import { FactsAndNumbers } from './FactsAndNumbers'
import { ContactFormAndInfo } from './ContactFormAndInfo'
import { PortfolioItemInfo } from './PortfolioItemInfo'

import { HeaderSimple } from './HeaderSimple'
import { TitleAndText } from './TitleAndText'
import { BlogText } from './BlogText'
import { CallToAction } from './CallToAction'
import { ImageAndText } from './ImageAndText'
import { Features } from './Features'
import { Steps } from './Steps'
import { Title } from './Title'

export const components = {
	header: Header,
	development: Development,
	marketing: Marketing,
	partners: Partners,
	portfolio: Portfolio,
	facts_and_numbers: FactsAndNumbers,
	contact_form_and_info: ContactFormAndInfo,
	portfolio_item_info: PortfolioItemInfo,

	header_simple: HeaderSimple,
	title_and_text: TitleAndText,
	blog_text: BlogText,
	call_to_action: CallToAction,
	image_and_text: ImageAndText,
	features: Features,
	steps: Steps,
	title: Title,
}