import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const ImageAndText = ({ slice }) => {

  const {reverse, title_pt_1, title_pt_2, text, img} = slice.primary

  return (
    <div className="container-fluid border-top">
      <div className="row">
        <div className={"col-md-6" + " " + (reverse ? "order-md-2" : "")} data-aos="fade-right">
          <div className="h-100 d-flex justify-content-center align-items-center section-p-y">
            <div style={{maxWidth:"400px"}}>
              <span className="h3">{title_pt_1 + " "}</span>
              <span className="h3 fw-bold">{title_pt_2}</span>
              <div className="line-3-70 mb-4"/>
                <PrismicRichText field={text.richText} />
            </div>
          </div>
        </div>
        <div className={"col-md-6 p-0" + " " + (reverse ? "order-md-1" : "")}>
          <div style={{background:"url(" + img.url + ") 50% 50% no-repeat",backgroundSize:"cover",width:"100%",height:"100%",minHeight:"440px"}}/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyImageAndText on PrismicHomepageDataBodyImageAndText {
    primary {
      reverse
      title_pt_1
      title_pt_2
      text {
        richText
      }
      img {
        url
      }
    }
  }
  fragment PageDataBodyImageAndText on PrismicPageDataBodyImageAndText {
    primary {
      reverse
      title_pt_1
      title_pt_2
      text {
        richText
      }
      img {
        url
      }
    }
  }
`
