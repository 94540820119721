import * as React from 'react'
import { useState } from 'react';
import { useForm } from "react-hook-form";
import {callHook} from '../utils/utils';

export default function SimpleContactForm(form) {

  const [isSend, setSend] = useState(false);
  const [isError, setError] = useState(false);

  const { register, setValue, handleSubmit, watch, formState: { errors, isSubmitted } } = useForm({
    mode: "onChange"
  });
  
  const onSubmit = async (data) => {
    data.pageUrl = form.pageUrl
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/website-forms";
    const r = await callHook({url, uid:"ak-media-contact-form", params:data});
    console.log(r);
    // if (r && r.success === true) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
    // setValue("full", "");
    setSend(true);
  }

  if (isSend) {
    return (
      <div className="text-start">
        {!isError && (
        <>
          <h3>Thank You!</h3>
          <p>We will contact you shortly.</p>
        </>
        )}
        {isError && (
        <>
          <h3>An error has occurred</h3>
          <p>Please try again later, server is not available now.</p>
        </>
        )}
        {/*<div className="col-auto">
          <button onClick={()=>setSend(false)} className={"btn text-uppercase btn-primary"}>{isError ? "Try again" : "Try again"}</button>
        </div>*/}
      </div>
    );
  }

  return (
    <div className="">
      <form className="text-start" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            placeholder="NAME"
            className={'form-control'}
            {...register('name', {required: false})}
          />
        </div>
        <div className={"mt-4"}>
          <input
            placeholder="EMAIL"
            className={"form-control"}
            required
            {...register('email', {required: true})}
          />
        </div>
        <div className={"mt-4"}>
          <textarea
            placeholder="MESSAGE"
            type="text"
            rows="5"
            className={"form-control"}
            required
            {...register('text', {required: true})}
          />
        </div>
        <input type="submit" value="Send message" className={"mt-4 btn btn-primary"}/>
      </form>
    </div>
  )
}

