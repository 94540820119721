import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const FactsAndNumbers = ({ slice }) => {

  const {title_pt_1, title_pt_2} = slice.primary

  const numItems = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-md-6 col-lg-4" data-aos="fade-zoom-in" data-aos-delay={index*100}>
        <div class="h-100 d-flex align-items-center">
          <img className="" height="40" width="40" src={item.img.url}/>
          <div className="ms-4">
					  <h4 className="fw-bold">{item.title}</h4>
					  <p className="text-uppercase mb-0">{item.desc.text}</p>
          </div>
				</div>
      </div>
    )
  })

  return (
    <div id="numbers" className="container section-p-y">
      <div className="row gy-5">
        <div className="col-12 col-md-6 col-lg-4">
          <h3 className="section-title mb-0">{title_pt_1 + " "}<br/><span className="fw-bold">{title_pt_2}</span></h3>
        </div>
        {numItems}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFactsAndNumbers on PrismicHomepageDataBodyFactsAndNumbers {
    primary {
      title_pt_1
      title_pt_2
    }
    items {
      img {
        url
      }
      title
      desc {
        text
      }
    }
  }
`
