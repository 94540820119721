import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { TopMenu } from './TopMenu'
import { Footer } from './Footer'

export const Layout = ({ children, topMenu, isTMWhite, footer, seo }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const title = seo.title || queryData.site.siteMetadata.title
  const metaDescription = seo.description || queryData.site.siteMetadata.description
  const keywords = seo.keywords || queryData.site.siteMetadata.keywords

  const meta = [{
      name: `description`,
      content: metaDescription,
    },{
      name: "keywords",
      content: keywords,
    },{
      property: `og:title`,
      content: title,
    },{
      property: `og:description`,
      content: metaDescription,
    },{
      property: `og:type`,
      content: `website`,
    },{
      name: `twitter:creator`,
      content: queryData.site.siteMetadata.author,
    },{
      name: `twitter:title`,
      content: title,
    },{
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  return (
    <>
      <Helmet
        title={title}
        meta={[
          ...meta,
        ]}>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <TopMenu topMenu={topMenu} isTMWhite={isTMWhite}/>
      <main>{children}</main>
      <Footer footer={footer}/>
    </>
  )
}
