import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Development = ({ slice }) => {

  const {anchor, title_pt_1, title_pt_2, text, img} = slice.primary

  return (
    <div id={anchor} className="container-fluid border-top">
      <div className="row">
        <div className="col-md-6 " data-aos="fade-right">
          <div className="h-100 d-flex justify-content-center align-items-center section-p-y">
            <div style={{maxWidth:"400px"}}>
              <span className="h2">{title_pt_1 + " "}</span>
              <span className="h2 fw-bold">{title_pt_2}</span>
              <div className="line-3-70 mb-4"/>
              <div className="">
                <PrismicRichText field={text.richText} />
              </div>
              <a className="btn btn-primary" href="/portfolio/">Our portfolio</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <div style={{background:"url(" + img.url + ") 50% 50% no-repeat",backgroundSize:"cover",width:"100%",height:"100%",minHeight:"440px"}}/>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyDevelopment on PrismicHomepageDataBodyDevelopment {
    primary {
      anchor
      title_pt_1
      title_pt_2
      text {
        richText
      }
      img {
        url
      }
    }
  }
`
