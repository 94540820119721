import * as React from 'react'
import { graphql } from 'gatsby'

import Typewriter from "typewriter-effect";

export const HeaderSimple = ({ slice }) => {

  const {title_pt_1, title_pt_2, cover_bg} = slice.primary

  return (
    <div className="bg-dark" style={{background:"url(" + cover_bg.url + ") 50% 50% no-repeat",backgroundSize:"cover",width:"100%",maxHeight:"665px",height:"50vh"}}>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="container text-center">
            <h1 className="h2 text-white fw-500">{title_pt_1}</h1>
            <h3 className="text-white mt-4">{title_pt_2}</h3>
        </div>
      </div> 
    </div>
  )
}

export const query = graphql`
fragment HomepageDataBodyHeaderSimple on PrismicHomepageDataBodyHeaderSimple {
    primary {
      title_pt_1
      title_pt_2
      cover_bg {
        url
      }
    }
  }
  fragment PageDataBodyHeaderSimple on PrismicPageDataBodyHeaderSimple {
    primary {
      title_pt_1
      title_pt_2
      cover_bg {
        url
      }
    }
  }
`
