import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import Carousel from 'react-bootstrap/Carousel';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

export const PortfolioItemInfo = ({ slice, context }) => {
  const {title, desc_short} = context
  const {desc} = slice.primary

  const images = slice.items || []
  let isImages = false
  if (images && images.length) {
    isImages = true
  }

  const galleryImages = images.map((items, index) => {
    const imgUrl = items.img.url;
    return (
      <Carousel.Item key={index} className="ar-16-9">
          <Item
            key={index}
            original={imgUrl}
            thumbnail={imgUrl}
            width={2400}
            height={2400}
          >
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={imgUrl}
                alt=""
                className="image-cover"
              />
            )}
          </Item>
      </Carousel.Item>
    );
  });

  return (
    <div>
      <div className="bg-light" style={{height:"80px"}}/>
      <div className="bg-light title-section-p-y">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="page-title">{context.title}</h1>
              <div className="text-uppercase mb-0-rt">
                <PrismicRichText field={context.desc_short.richText} />
              </div>
            </div>           
          </div>
        </div>
      </div>
      
      <div className="border-bottom">
        <div className="container section-p-b">
          <div className="btn-back-m"><a href="/" className="p-2 ps-0 text-link text-uppercase text-decoration-none d-flex align-items-center"><img className="me-2 details-icon" height="12" width="12" src={`/icons/back.svg`}/> BACK TO HOME</a></div>
          {isImages && (
            <div className="bg-light ar-16-9" style={{cursor:"pointer"}}>
              <Gallery>
                <Carousel>
                  {galleryImages}
                </Carousel>
              </Gallery>
            </div>
          )}
          <div className="mt-5">
            <PrismicRichText field={desc.richText} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PrismicPortfolioItemPortfolioItemInfo on PrismicPortfolioItemDataBodyPortfolioItemInfo {
    id
    slice_type
    slice_label
    primary {
      desc {
        richText
      }
    }
    items {
      img {
        url
      }
    }
  }
`
