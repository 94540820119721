import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { useLocation } from '@reach/router';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export const TopMenu = ({ topMenu, isTMWhite }) => {

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => {
      
      return (
        <div key={`top-nav-${index}`}>
          <Nav.Link href={menuLink.anchor ? ("#" + menuLink.anchor) : menuLink.link.url} className="text-uppercase">{menuLink.label}</Nav.Link>
        </div>
      )
    })
  : null

  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg={isTMWhite ? "white" : "transparent"} variant={scroll ? "light" : (isTMWhite ? "light" : "dark")} className={scroll ? "scrolled" : ""}>
      <div className="container-fluid px-3 px-lg-5">
      <Navbar.Brand href="/"><img className="" height="40" src={topMenu.logo.url}/></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          {useLocation().pathname == "/" && renderedMenuLinks}
        </Nav>
      </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export const query = graphql`
  fragment TopmenuFragment on PrismicTopmenu {
    _previewable
    type
    lang
    data {
      logo {
        url
      }
      title
      menu_links {
        label
        anchor
        link {
          id
          url
        }
      }
    }
  }
`
