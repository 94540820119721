import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Steps = ({ slice }) => {

  const {anchor, title_pt_1, title_pt_2} = slice.primary

  const steps = slice.items.map((item, index) => {
    return (
      <div key={index} className="col-12 col-md-6 col-lg-4" data-aos="fade-zoom-in" data-aos-delay={index*100}>
        <div className="h-100 overflow-hidden">
          <div className="d-flex">
            <div className="d-flex h-100 w-100 position-relative">
              <div className="w-100 d-flex align-items-center py-2 ps-4 pe-2" style={{backgroundColor:"#4b4e53"}}>
                <h3 className="text-white fw-bold mb-0 me-3">{index+1}</h3>
				        <h4 className="text-white fw-bold mb-0">{item.title}</h4>
              </div>
            </div>
          </div>
          <div className="h-100">
            <div className="bg-light p-4 h-100 mb-0-rt">
              <PrismicRichText field={item.text.richText} />
            </div>
          </div>
        </div>
        {/*<div className="bg-white py-4 px-5 pe-4 w-100">
            <div className="d-flex align-items-center">
              <h2 className="fw-bold text-white ak-media me-3">{index+1}</h2>
				      <h4 className="fw-bold">{item.title}</h4>
            </div>
            <div className="my-3" style={{height:"2px",width:"48px",background:"#4b4e53"}}/>
				    
          </div>
          <div className="triangle-r" />*/}
      </div>
    )
  })

  return (
    <div id={anchor} className="bg-white">
      <div className="container section-p-y">
        <h3 className="text-center mb-5">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>
        <div className="row g-4">
          {steps}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodySteps on PrismicHomepageDataBodySteps {
    primary {
      anchor
      title_pt_1
      title_pt_2
    }
    items {
      title
      text {
        richText
      }
    }
  }
  fragment PageDataBodySteps on PrismicPageDataBodySteps {
    primary {
      anchor
      title_pt_1
      title_pt_2
    }
    items {
      title
      text {
        richText
      }
    }
  }
`
