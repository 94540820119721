import * as React from 'react'
import { graphql } from 'gatsby'

const { defaultLanguage } = require('../../prismic-configuration')

export const Footer = ({ footer }) => {
  const footerData = footer.data
  const {copyright} = footerData;

  const renderedFooterLinks = footerData
    ? footerData.footer_links.map((footerLink, index) => {
      // const footerLinkUrl = footer.lang === defaultLanguage ? footerLink.link.url : `/${footer.lang}/${footerLink.link.url}`;
      return (
        <div key={index} className="col-auto text-center">
          <a href={footerLink.link.url} className="h5 mb-0 text-uppercase text-decoration-none text-link">{footerLink.label}</a>
        </div>
      )
    })
  : null

  return (
    <footer className="mt-auto flex-column">
      <div className="container py-5">
        <div className="row gy-3 justify-content-center border-bottom pb-5">
          {renderedFooterLinks}
        </div>
        <div className="pt-5">
          <div className="text-center fs-11">{copyright.text}</div>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    lang
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          id
          url
        }
      }
    }
  }
`