import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const CallToAction = ({ slice }) => {
  const {title, btn_label, btn_link, bg_img} = slice.primary

  const handleClick = () => {
    onClickCTA()
  }

  return (
    <div className="bg-light" style={{background:"url(" + bg_img.url + ") 50% 50% no-repeat",backgroundSize:"cover",width:"100%"}}>
      <div className="container py-5">
      <div className="d-flex justify-content-center align-items-center">
          <h4 className="fw-bold mb-0 me-4">{title}</h4>
          <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
          <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>
          <a id="cta-click" onClick={handleClick} className="btn btn-secondary">{btn_label}</a>
      </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCallToAction on PrismicHomepageDataBodyCallToAction {
    primary {
      title
      btn_label
      btn_link {
        url
      }
      bg_img {
        url
      }
    }
  }
  fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
    primary {
      title
      btn_label
      btn_link {
        url
      }
      bg_img {
        url
      }
    }
  }
`
