import * as React from 'react'
import { graphql } from 'gatsby'
import SimpleContactForm from '../components/SimpleContactForm';

export const ContactFormAndInfo = ({ slice }) => {

  const {anchor, title_pt_1, title_pt_2, phone, email, address, coordinates} = slice.primary

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }  

  return (
    <div id={anchor}>
      <div className="container-fluid bg-light">
        <div>
          <div className="row mx-0 w-100">
            <div className="col-lg-6 p-0">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
                <div>
                  <div className="d-flex align-items-center">
                    <img className="" height="36" width="36" src={`/icons/address.svg`}/>
                    <div className="cis-text ms-4">
                      <h5 className="fw-bold">ADDRESS</h5>
                      <a className="text-decoration-none text-uppercase text-body" href={coordinates ? ("http://maps.google.com/maps/place/" + coordinates.latitude + "," +       coordinates.longitude) : "javascript:void(0);"}>{address}</a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3 mt-md-5">
                    <img className="" height="36" width="36" src={`/icons/email.svg`}/>
                    <div className="cis-text ms-4">
                    <h5 className="fw-bold">EMAIL</h5>
                      <a className="text-decoration-none text-uppercase text-body" href={"mailto:" + email}>{email}</a>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3 mt-md-5">
                    <img className="" height="36" width="36" src={`/icons/phone.svg`}/>
                    <div className="cis-text ms-4">
                      <h5 className="fw-bold">CALL US</h5>
                      <a className="text-decoration-none text-uppercase text-body" href={"tel:" + phone}>{phone}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 py-5 mb-0">
              <div className="py-4">
                <div className="px-0 px-lg-5">
                  <h3 className="section-title mb-4">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>
                  <SimpleContactForm formId={"ak-media-simple-form"} pageUrl={pageUrl}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyContactFormAndInfo on PrismicHomepageDataBodyContactFormAndInfo {
    primary {
      anchor
      title_pt_1
      title_pt_2
      phone
      email
      address
      coordinates {
        latitude
        longitude
      }
    }
  }
  fragment PageDataBodyContactFormAndInfo on PrismicPageDataBodyContactFormAndInfo {
    primary {
      anchor
      title_pt_1
      title_pt_2
      phone
      email
      address
      coordinates {
        latitude
        longitude
      }
    }
  }
`
