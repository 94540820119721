import * as React from 'react'
import { graphql } from 'gatsby'

import Typewriter from "typewriter-effect";

export const Header = ({ slice }) => {

  const {title_pt_1, title_pt_2, title_pt_3, title_end_1, title_end_2, title_end_3, cover_img} = slice.primary

  return (
    <div className="bg-dark" style={{background:"url(" + cover_img.url + ") 50% 50% no-repeat",backgroundSize:"cover",width:"100%",maxHeight:"665px",height:"70vh"}}>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <div className="d-none d-md-block">
            <span className="h1 text-white mb-0">{title_pt_1 + " "}</span>
            <span className="h1 text-white ak-media fw-500 mb-0" >{title_pt_2}</span>
            <span className="h1 text-white mb-0">{" " + title_pt_3 + " "}</span>
          </div>
          <div className="d-block d-md-none">
            <h1 className="text-white mb-0">{title_pt_1 + " "}</h1>
            <span className="h1 text-white ak-media fw-500 mb-0" >{title_pt_2}</span>
            <h1 className="text-white mb-0">{" " + title_pt_3 + " "}</h1>
          </div>
          <div className="d-flex justify-content-center">
            <Typewriter
              options={{
                strings: [
                  '<span class="h1 text-white fw-bold mb-0">' + title_end_1 + "</span>",
                  '<span class="h1 text-white fw-bold mb-0">' + title_end_2 + "</span>",
                  '<span class="h1 text-white fw-bold mb-0">' + title_end_3 + "</span>"
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        </div>
      </div> 
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyHeader on PrismicHomepageDataBodyHeader {
    primary {
      title_pt_1
      title_pt_2
      title_pt_3
      title_end_1
      title_end_2
      title_end_3
      cover_img {
        url
      }
    }
  }
`
