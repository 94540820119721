import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { components } from '../slices'
import { Layout } from '../components/Layout'

import AOS from 'aos';
import 'aos/dist/aos.css';

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      id
      lang
      type
      url
      data {
        seo_title
        seo_description
        seo_keywords
        page_title
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
					...HomepageDataBodyHeader
          ...HomepageDataBodyDevelopment
          ...HomepageDataBodyMarketing
          ...HomepageDataBodyPartners
          ...HomepageDataBodyPortfolio
          ...HomepageDataBodyFactsAndNumbers
          ...HomepageDataBodyContactFormAndInfo
          ...HomepageDataBodyHeaderSimple
          ...HomepageDataBodyCallToAction
          ...HomepageDataBodyImageAndText
          ...HomepageDataBodyFeatures
          ...HomepageDataBodySteps
          ...HomepageDataBodyTitle
        }
      }
    }
    prismicTopmenu {
      ...TopmenuFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
    allPrismicPortfolioItem(
    sort: { fields: [data___sort], order: ASC }
    limit: 10
    skip: 0
  ) {
    nodes {
      _previewable
      uid
      url
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        desc_short {
          text
          richText
        }
        cover_img {
          url
        }
        body {
          ... on PrismicPortfolioItemDataBodyPortfolioItemInfo {
            id
            slice_type
            primary {
              desc {
                text
                richText
              }
            }
          }
        }
      }
    }
  }
  }
`

const Homepage = ({ data }) => {
  if (!data) return null

  useEffect( ()=> {
    AOS.init({
      once: true,
    });
  }, [])

  const homepage = data.prismicHomepage || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const topMenu = data.prismicTopmenu || {}
  const footer = data.prismicFooter || {}

  const allPortItems = data.allPrismicPortfolioItem
  console.log("allPortItems", allPortItems)

  const cntx = {portItems: allPortItems.nodes}

  return (
    <Layout seo={seo} topMenu={topMenu.data} footer={footer}>
      <SliceZone slices={homepage.data.body} components={components} context={cntx}/>
    </Layout>
  )
}
export default withPrismicPreview(Homepage)
