import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TitleAndText = ({ slice }) => {
  const {title, text} = slice.primary

  return (
    <div className="container mb-60px">
      {title &&
        <div className="border-bottom">
          <h4 className="fw-bold pb-2">{title}</h4>
        </div>
      }
      <div className="mt-4">
        <PrismicRichText field={text.richText} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyTitleAndText on PrismicPageDataBodyTitleAndText {
    primary {
      title
      text {
        richText
      }
    }
  }
`
