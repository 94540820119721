const { defaultLanguage } = require('../../prismic-configuration')
/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 */
exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'homepage': {
      return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`
    }
    // unused
    // case 'portfolio': {
    //   return `/portfolio/`
    // }

    case 'portfolio_item': {
      return `/portfolio/${doc.uid}`
    }

    case 'page': {
      // return `/${doc.uid}`
      return doc.lang === defaultLanguage
        ? `/${doc.uid}`
        : `/${doc.lang}/${doc.uid}`
    }

    case 'blog': {
      // return `/blog/`
      return doc.lang === defaultLanguage ? '/blog/' : `/${doc.lang}/blog/`
    }

    // case 'blog_item': {
    //   // return `/blog/${doc.uid}`
    //   return doc.lang === defaultLanguage
    //     ? `/blog/${doc.uid}`
    //     : `/blog/${doc.lang}/${doc.uid}`
    // }

    default:
      return '/'
  }
}
