import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Title = ({ slice }) => {

  const {anchor, title_pt_1, title_pt_2} = slice.primary


  return (
    <div id={anchor} className="bg-light">
      <div className="container section-p-y">
        <h3 className="text-center mb-0">{title_pt_1 + " "}<span className="fw-bold">{title_pt_2}</span></h3>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTitle on PrismicHomepageDataBodyTitle {
    primary {
      anchor
      title_pt_1
      title_pt_2
    }
  }
`
